import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import AccessingMessage from '../accessingMessage/AccessingMessage';
import UrlParser from 'js-video-url-parser';
import VideoElement from '../player/VideoElement';
import ImageElement from '../player/ImageElement';
import LinkElement from '../player/LinkElement';
import { firstTimeStatus, playingStatus, updateElementIndex } from '../../reducers/players/playersSlice';
import Logo from '../../assets/logo_ads.png';
import PlayLogo from '../../assets/play.png';
import { Box } from '@mui/material';
import MessageElement from '../player/MessageElement';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Virtual, EffectCube, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";

const PREFIX = 'StoriesDisplayer';

const classes = {
  progressContainer: `${PREFIX}-progressContainer`,
  playerContainer: `${PREFIX}-playerContainer`,
  playOverlay: `${PREFIX}-playOverlay`,
  logoAds: `${PREFIX}-logoAds`,
  videoPlayButton: `${PREFIX}-videoPlayButton`,
  playButtonImage: `${PREFIX}-playButtonImage`,
  stepDisplayer: `${PREFIX}-stepDisplayer`,
  arrowUpStyle: `${PREFIX}-arrowUpStyle`,
  arrowDownStyle: `${PREFIX}-arrowDownStyle`
};

SwiperCore.use([Virtual, Pagination]);

const StyledDiv = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.playerContainer}`]: {
    position: 'absolute',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    top: 0,
    left: 0,
    'msOverflowStyle': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  [`& .${classes.playOverlay}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 99,
  },
  [`& .${classes.logoAds}`]: {
    width: '150px',
    position: 'absolute',
    left: '50%',
    top: '2.5rem',
    transform: 'translateX(-50%)',
  },
  [`& .${classes.videoPlayButton}`]: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    '& > .MuiSvgIcon-root': {
      fontSize: '4rem !important',
      cursor: 'pointer'
    }
  },
  [`& .${classes.playButtonImage}`]: {
    maxWidth: '300px',
    width: '100%',
  },
  [`& .${classes.stepDisplayer}`]: {
    position: 'absolute',
    right: '95%',
    top: '50%',
    color: 'rgba(255, 255, 255, 0.8)',
    zIndex: 2,
    fontSize: '9px',
    transform: 'translateY(-6px)'
  },
  [`& .${classes.arrowUpStyle}`]: {
    position: 'absolute',
    width: '18px',
    transform: 'translate(12.75px, -34px)',
  },
  [`& .${classes.arrowDownStyle}`]: {
    position: 'absolute',
    width: '18px',
    transform: 'translate(12.75px, 23px)',
  }
}));

const StoriesDisplayer = ({ token, direction }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [swiperRef, setSwiperRef] = useState(null);
  const [slideChanging, setSlideChanging] = useState(false);

  const playlist = useSelector(state => state.players.playlist);
  const currentIndex = useSelector(state => state.players.currentPlayingIndex);
  const error = useSelector(state => state.players.error);
  const loading = useSelector(state => state.players.loading);
  const firstTime = useSelector(state => state.players.firstTime);
  const playing = useSelector(state => state.players.playing);

  const progressCircle = useRef(null);
  const progressContent = useRef(null);

  const onAutoplayTimeLeft = (s, time, progress, duration) => {
    const swiper = document.querySelector(".swiper").swiper
    if (!firstTime) {
      progressCircle.current.style.setProperty('--progress', 1 - swiper.autoplay.timeLeft / (duration * 1000));
    }
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  const accessingMessagesComponents = useMemo(() => ({
    "token-error": <AccessingMessage text={t('tokenError')} error />,
    "token-expired": <AccessingMessage text={t('tokenExpired')} error />,
    unknown: <AccessingMessage text={t('unknownError')} error />,
    fetching: <AccessingMessage text={t('fetching')} loader />,
    noResults: <AccessingMessage text={t('noResults')} />,
  }), [t]);

  useEffect(() => {
    if (!firstTime && direction !== 'vertical') {
      swiperRef.autoplay.start();
      swiperRef.autoplay.resume();
    } else {
      if (swiperRef) {
        swiperRef.autoplay.paused = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstTime, swiperRef])

  useEffect(() => {
    if (swiperRef) {
      if (slideChanging)
        swiperRef.autoplay.resume()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideChanging])

  useEffect(() => {
    if (!firstTime) {
      if (!playing) {
        // swiperRef.autoplay.stop();
        swiperRef.autoplay.pause();
      } else {
        // swiperRef.autoplay.start();
        swiperRef.autoplay.resume();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, swiperRef])

  if (error && error !== 'token-error' && error !== 'token-expired') {
    return accessingMessagesComponents.unknown;
  } else if (error) {
    return accessingMessagesComponents[error];
  } else if (loading || !playlist || !playlist[currentIndex]) {
    return accessingMessagesComponents.fetching;
  } else if (playlist && playlist.length === 0) {
    return accessingMessagesComponents.noResults;
  } else {
    return (
      <StyledDiv>
        {firstTime &&
          <div
            className={classes.playOverlay}
          >
            <div className={classes.videoPlayButton}>
              <img
                src={PlayLogo}
                alt="Play Icon"
                className={classes.playButtonImage}
                onClick={() => {
                  dispatch(playingStatus());
                  dispatch(firstTimeStatus());
                }}
              />
            </div>
            <img
              src={Logo}
              alt="logo ArtDesignStory"
              className={classes.logoAds}
            />
          </div>
        }
        <Box
          className={classes.playerContainer}
          sx={{
            filter: firstTime ? 'blur(6px)' : 'none',
          }}
        >
          {direction === "vertical" &&
            <div className={classes.stepDisplayer}>
              <KeyboardArrowUpIcon className={classes.arrowUpStyle} />
              <KeyboardArrowDownIcon className={classes.arrowDownStyle} />
              {currentIndex + 1}/{playlist.length}
            </div>
          }
          <Swiper
            onSlideChange={(elem) => {
              setSlideChanging(true);
              dispatch(updateElementIndex(elem.activeIndex))
            }}
            onSlideChangeTransitionEnd={() => setSlideChanging(false)}
            onSlideResetTransitionStart={() => {
              setSlideChanging(false)
            }}
            onSlideResetTransitionEnd={() => {
              setSlideChanging(false)
            }}
            onInit={() => {
              const inter = setInterval(() => {
                const swiper = document.querySelector('.swiper').swiper;
                if (swiper) {
                  setSwiperRef(swiper)
                  swiper.autoplay.stop();
                  clearInterval(inter);
                }
              }, 100)
            }}
            effect={direction === "vertical" ? "" : "cube"}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 20,
              shadowScale: 0.94,
            }}
            // autoplay={{
            //   delay: parseInt(playlist[currentIndex].duration) * 1000 || 8000,
            //   disableOnInteraction: false,
            //   pauseOnMouseEnter: true,
            // }}
            autoplay={false}
            modules={direction === "vertical" ? [Autoplay, Pagination] : [EffectCube, Autoplay, Pagination]}
            // onAutoplayTimeLeft={!firstTime && playing ?
            //   (s, time, progress) => onAutoplayTimeLeft(s, time, progress, playlist[currentIndex].duration ? parseInt(playlist[currentIndex].duration) : 8)
            //   : undefined
            // }
            pagination={{
              dynamicBullets: playlist.length > 15,
              dynamicMainBullets: 1,
            }}
            direction={direction}
            // virtual={{
            //   cache: true,
            //   enabled: true,
            // }}
            className="mySwiper"
          >
            {playlist.map((item, index) => {
              const duration = parseInt(item.duration) * 1000;
              const isFromProvider = item.targetUrl?.includes('googlevideo.com');
              if (item.type === 'video' || UrlParser.parse(item.targetUrl) || isFromProvider) {
                return (
                  <SwiperSlide key={`swiper-slide-${index + 1}`} data-swiper-autoplay={duration || '8000'} >
                    {({ isVisible, isActive }) => (
                      <VideoElement
                        url={item.targetUrl}
                        token={token}
                        currentIndex={index}
                        disableProgress={direction !== "vertical"}
                        isVisible={isVisible}
                        isActive={isActive}
                        verticalPlayer={direction === "vertical"}
                      />
                    )}
                  </SwiperSlide>
                )
              } else if (item.type === 'image') {
                return (
                  <SwiperSlide key={`swiper-slide-${index + 1}`} data-swiper-autoplay={duration || '8000'} >
                    {({ isVisible, isActive }) => (
                      <ImageElement
                        key={`image-element-${index + 1}`}
                        url={item.targetUrl}
                        token={token}
                        currentIndex={index}
                        isVisible={isVisible}
                        isActive={isActive}
                        verticalPlayer={direction === "vertical"}
                      />
                    )}
                  </SwiperSlide>
                )

              }
              else if (item.type === 'message') {
                return (
                  <SwiperSlide key={`swiper-slide-${index + 1}`} data-swiper-autoplay={duration || '8000'}  >
                    {({ isVisible, isActive }) => (
                      <MessageElement
                        key={`link-element-${index + 1}`}
                        message={item.message}
                        token={token}
                        currentIndex={index}
                        isVisible={isVisible}
                        isActive={isActive}
                        verticalPlayer={direction === "vertical"}
                      />
                    )}
                  </SwiperSlide>
                )
              } else {
                return (
                  <SwiperSlide key={`swiper-slide-${index + 1}`} data-swiper-autoplay={duration || '8000'} >
                    {({ isVisible, isActive }) => (
                      <LinkElement
                        key={`link-element-${index + 1}`}
                        url={item.targetUrl}
                        token={token}
                        currentIndex={index}
                        isVisible={isVisible}
                        isActive={isActive}
                        verticalPlayer={direction === "vertical"}
                      />
                    )}
                  </SwiperSlide>
                );
              }
            })}
            {/* <div className="autoplay-progress" slot="container-end" style={{display: direction === 'vertical' ? 'none' : 'flex'}}>
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                {!slideChanging &&
                  <circle cx="24" cy="24" r="20"></circle>
                }
              </svg>
              <span ref={progressContent} style={{ display: slideChanging ? 'none' : 'block' }} />
            </div> */}
          </Swiper>
        </Box>
      </StyledDiv>
    )
  }
}

export default StoriesDisplayer;